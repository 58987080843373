import Vue from "vue";
//scripts gerados a partir dos gerados pelo webChats, consultar
const lang = Vue.prototype.$lang_.getLang().title || "pt";
let imgSrc = require("@/assets/images/sacDesktop-pt-BR.png");
if (lang === "es") {
    imgSrc = require("@/assets/images/sacDesktop-es-ES.png");
} else if (lang === "en") {
    imgSrc = require("@/assets/images/sacDesktop-en-US.png");
} else {
    imgSrc = require("@/assets/images/sacDesktop-pt-BR.png");
}

const mobile = require("@/assets/images/sacMobile.png");
export const deskManager = {
    dwhatToggle() {
        var e,
            t = document.getElementById("dwhatDiv"),
            a = document.getElementById("dwhats");
        null == t
            ? (((e = document.createElement("iframe")).src =
                  "https://webchat.botframework.com/embed/webchat_maracanatour_imply_desk_ms?s=ibSlecNymXQ.qUszG0XSBzMatA8yujJ1dK22Tylk-o2s5-eYood21pI"),
              (e.id = "dwhatDiv"),
              (e.style =
                  "border-radius: 5px;position: fixed;border: 1px ridge;width: 400px;bottom: 148px;height: 500px;right: 10px;max-width: 94%; z-index: 99 !important"),
              document.body.appendChild(e),
              a.classList.remove("dwhatO"),
              a.classList.add("dwhatC"))
            : "none" === t.style.display
            ? ((t.style.display = "block"),
              a.classList.remove("dwhatO"),
              a.classList.add("dwhatC"))
            : (a.classList.add("dwhatO"),
              a.classList.remove("dwhatC"),
              (t.style.display = "none"));
    },
    dwhatLoad() {
        var e = document.createElement("style");
        (e.innerHTML = `.dwhatC::before{background:url(${imgSrc})top center no-repeat;background-size: cover;}.dwhatO::before{background:url(${imgSrc})top 
			center no-repeat;background-size: cover;}#dwhats{width:168px;height:106px;display:block;cursor: pointer;}
            @media(max-width:958px){#dwhats{width:120px;height: 120px;}.dwhatO::before{background:url(${mobile})top 
			center no-repeat;background-size: cover;}.dwhatC::before{background:url(${mobile})top center no-repeat;background-size: cover;}}
            #dwhats{position:fixed;bottom:48px;z-index:9999999999;display:flex;align-items:center;right:15px;}
			#dwhats::before{content:\"\";width:100%;height:100%;display: block;}`),
            document.head.appendChild(e);
        let link = document.createElement("a");
        link.addEventListener("click", () => {
            this.dwhatToggle();
        });
        (link.id = "dwhats"),
            (link.className = "dwhatO"),
            link.classList.add("animate__animated", "animate__fadeInUp");
        document.body.appendChild(link);
    },
};
